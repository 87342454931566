<template>
  <div class="content">
    <div class="bg-top">
      <div class="title">公路全功能智能终端</div>
      <div  class="version">ALADDIN 4101</div>
      <div class="data-total">
        <van-row>
          <van-col span="8" class="border-right">
            <div class="num" v-text="info.devTotal"></div>
            <div class="text">设备总数</div>
          </van-col>
          <router-link to="/itgs/control/warn">
            <van-col span="8" class="border-right">
              <div class="num" v-text="info.todayWarn"></div>
              <div class="text">今日告警</div>
            </van-col>
          </router-link>
          <van-col span="8">
            <div class="num" v-text="info.offline"></div>
            <div class="text">离线数</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="inner">
      <!-- <van-collapse v-model="activeProject" accordion>
        <van-collapse-item v-for="(item,index) in projectList" :title="item.projName+'-'+item.projDetail" :name="item.projCode" :key="index"> -->
          <div v-if="isLoading">
            <van-loading class="z-loading" size="24px" vertical>加载中...</van-loading>
          </div>
          <div v-else>
            <div v-if="devData.length != 0">
              <div class="block global-shadow" v-for="(item,index) in devData" :key="index">
                <router-link :to="{path:'/itgs/Detail', query: {devCode:item.devCode,status:item.devStatus,devType:item.devType}}">
                  <van-row>
                    <van-col span="6">
                      <div class="star-box">
                          <img :src="item.devType=='EV4101' ? require('@/assets/itgs/720c-img.png') : require('@/assets/itgs/home-img.png')">
                      </div>
                    </van-col>
                    <van-col span="15" class="font">
                      <div :class="item.devStatus=='online' ? 'name' :' name offline'" v-text="item.devName || '暂无名称'"></div>
                      <div :class="item.devStatus=='online' ? 'text' :' text offline'" >设备编号：<span v-text="item.devCode"></span></div>
                      <!-- <div :class="item.devStatus=='1' ? 'text' :' text offline'">所属项目：<span v-text="item.projName || '未分配'"></span></div> -->
                      <div :class="item.devStatus=='online' ? 'text' :' text offline'">更新时间：<span v-text="item.updateTime || '无记录'"></span></div>
                    </van-col>
                    <van-col span="3">
                      <div class="icon">
                          <i v-if="item.devStatus=='online'" class="fa fa-wifi"></i>
                      </div>
                    </van-col>
                  </van-row>
                </router-link>
              </div>
            </div>
            <div v-else><van-empty description="设备列表为空" /></div>
          </div>
        <!-- </van-collapse-item>
      </van-collapse> -->
    </div>
    <!-- <div v-if="warnHint" class="fixe-notice"><van-notice-bar mode="closeable" left-icon="volume-o" :text="warnArr+'设备外部供电断开'"/></div> -->
    <tabBar/>
  </div>
</template>

<script>
import tabBar from '@/components/itgs/tabBar.vue'
export default {
  name: 'Home',
  components: {
    tabBar
  },
  data() {
    return {
      info:{
        devTotal:0,
        todayWarn:0,
        offline:0,
      },
      devData:[],
      isLoading: false,
      // warnHint:false,
      // warnArr:[],
      // pageNum:1, // 当前页码
      // pageSize :10, // 每页显示数量
      // loading:false,
      // finished:false,
      // activeProject:'',
      projectList:[],
      projectCode:null
    };
  },
  mounted(){
    // 获取projectCode
    this.projectCode = window.sessionStorage.getItem('projectCode');
    this.$api.ITGS.getStat({
      projectCode:this.projectCode
    }).then(({...data})=>{
      this.info = {...data};
    });
    this.devList();
  },
  methods:{
    // 获取项目下设备列表
    devList(){
      this.isLoading = true;
      this.$api.ITGS.getProjectDev({
        projectCode:this.projectCode
      }).then( d => {
        this.devData = d
        this.isLoading = false;
      })
    }
    //分页加载设备数据
    // onLoad(){
    //     this.$api.ITGS.getDevInfoPage({
    //       pageNum :this.pageNum,
    //       pageSize :this.pageSize 
    //     }).then((data)=>{
    //       if(this.devData.length==0){
    //           this.devData = data.records;
    //       }else{
    //           this.devData = this.devData.concat(data.records);
    //       }
    //       data.pages == this.pageNum ? this.finished = true : '' ;
    //       this.loading = false;
    //       this.pageNum ++;
    //     }).catch(()=>{
    //       this.finished = true
    //     })
    // }
  },
}
</script>
<style lang="scss" scoped>
$itemColor:#0957ff;
.content{margin-bottom: 18%;}
.fixe-notice{position: fixed;bottom: 7%;width: 100%;z-index: 999;}
.bg-top{
  text-align: center;
  position: relative;
  background:url('../../../assets/itgs/home-banner.jpg') no-repeat;
  background-size: 100% 80%;
  padding: 2%;
  font-weight: bold;
  text-align: left;
  color: #fff;
  .title{margin-top: 15%;font-size: 1.2rem;padding-left: 6%;letter-spacing: 1px;}
  .version{font-weight: 300;margin: 2% 0 15% 0;padding-left: 6%;letter-spacing: 1px;}
  .data-total{
    text-align: center;
    color: #000;
    background-color: white;
    padding: 4%;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    width: 92%;
    height: 3rem;
    box-shadow: 0 3px 4px #cad7ea;
    .border-right{
      border-right: 1px solid #e0e0e0;
    }
    .num{font-size: 1.55rem;color: #000}
    .text{font-size: 1rem;color: #656565;margin-top: 4px;font-weight: normal;}
  }
}
.inner{
  padding: 2%;
  .icon{
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    .fa{color: $itemColor;}
  }
  .block{
    padding: 4%;
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    margin-bottom: 4px;
    .font{line-height: 2;padding-left: 10px;}
    .star-box{
        width: 5rem;height: 5rem;color: $itemColor;display: flex;justify-content: center;align-items: center;
        img{width: 3.2rem;height: 4.3rem;}
    }
    .name{font-weight: bold;font-size: 1rem;color: $itemColor;}
    .text{font-size: .75rem;color: $itemColor;opacity: .8;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;}
    .offline{color: #656565;}
  }
}
</style>